<template>
  <!-- 开票管理 -->
  <div class="purchase-plan-list">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :reset="true" :form-inline="formInline" :form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <Table :operation-button="operationButton" :operation-button-width="100" :item-data="itemData" :list-data="listData" :loading="loading" />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { invoiceIssureQueryByPage } from '@/api/generationMining'
export default {
  components: {
    FormSearch,
    Table,
    Pagination
  },
  data() {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      formItemArr: [
        { type: 'input', label: '业务单号', value: 'busiNo' },
        { type: 'input', label: '开票方', value: 'openCmpName' },
        { type: 'input', label: '收票方', value: 'recvCmpName' },
        { type: 'picker', label: '申请时间', value: 'createTime', startTimer: 'applyTimeBegin', endTimer: 'applyTimeEnd' },
        { type: 'select', label: '发票状态', value: 'state', pLabel: 'dictName', pValue: 'dictId', child: [{ dictName: '开票中', dictId: '01' }, { dictName: '已开票', dictId: '02' }], width: '70' }
      ],
      itemData: [
        { label: '业务单号', prop: 'busiNo', width: 150 },
        { label: '业务类型', prop: 'busiType', width: 150, child: [{ dictName: '代采合同', dictId: 1 }, { dictName: '现货合同', dictId: 2 }] },
        // { label: '快递单号', prop: 'courierNumber', width: 220 },
        { label: '发票金额(元)', prop: 'invoiceAmt', width: 180 },
        // { label: '发票代码', prop: 'invoiceCode', width: 80 },
        // { label: '发票内容', prop: 'invoiceContent', width: 120 },
        // { label: '发票号码', prop: 'invoiceNo', width: 120 },
        // { label: '发票抬头名称', prop: 'invoiceTitleName', width: 220 },
        { label: '发票类型', prop: 'invoiceType', width: 180, child: [{ dictName: '增值税普通发票', dictId: '00' }, { dictName: '增值税专用发票', dictId: '01' }] },
        { label: '接收方式', prop: 'receiveType', width: 180, child: [{ dictName: '电子发票', dictId: '00' }, { dictName: '纸质发票', dictId: '01' }] },
        { label: '开票方会员名称', prop: 'openCmpName', width: 200 },
        { label: '收票方会员名称', prop: 'recvCmpName', width: 120 },
        { label: '发票开具日期', prop: 'openTime', width: 120 },
        { label: '申请时间', prop: 'createTime', width: 140 },
        { label: '发票状态', prop: 'state', width: 160, child: [{ dictName: '开票中', dictId: '01' }, { dictName: '已开票', dictId: '02' }] }
      ],
      operationButton: [
        { bType: 'primary', label: '详情', handleEvent: this.viewInfoTable }
      ],
      listData: [],
      loading: false,
      total: 0
    }
  },
  methods: {
    // 获取列表
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      invoiceIssureQueryByPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    },
    // 详情
    viewInfoTable(row) {
      this.$router.push({ path: '/financialManage/billingDetails', query: { id: row.id, busiId: row.busiId }})
    },
    // 付款
    async paymentTable() {
      const confirm = await this.$confirm('确认付款？', '提示', { type: 'warning' }).catch(err => err)
      if (confirm !== 'confirm') return
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
